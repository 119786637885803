<template>
  <section class="section is-main-section">
    <div class="box">
      <div class="columns is-mobile">
        <div class="column">
          <SelectLocation v-model="search.locationId" />
        </div>
        <role-search-n-clear v-model="search.roleId" @clearSearch="clearSearch" />
      </div>
    </div>

    <div class="columns">
      <div class="column">
        <ContextTableCard
          title="Actions"
          icon="gesture-tap"
          :header-icons="headerIcons"
          :tableData="tableData"
          :sortField="search.sortField"
          :sortOrder="search.sortOrder"
          :page="search.page"
          :total="total"
          @refreshData="debounceRefreshData"
          @page-change="pageChange"
          @sort="sort"
          v-model="selected"
          :row-class="setActionColour"
          @contextDefaultClicked="editAction"
          :menuOptions="contextOptions"
          @menu-clicked="contextMenuClicked"
          :isLoading="isLoading"
        >
          <b-table-column label="Due" field="dueDate" sortable v-slot="props">
            {{ shortFormat(props.row.dueDate) }}
          </b-table-column>
          <b-table-column label="Action" field="description" sortable v-slot="props">
            {{ props.row.description }}
          </b-table-column>
          <b-table-column label="Location" field="locationName" sortable v-slot="props">
            {{ props.row.locationName }}
          </b-table-column>
          <b-table-column label="Role" field="roleName" sortable v-slot="props">
            {{ props.row.roleName }}
          </b-table-column>
          <b-table-column label="Status" field="status" sortable v-slot="props">
            <div class="is-selected invertCellPadding" :class="setActionColour(props.row)">
              <b-icon :icon="icon(props.row.status)" size="is-small" /> {{ props.row.statusDesc }}
            </div>
          </b-table-column>
          <b-table-column label="Risk" field="riskDesc" sortable v-slot="props">
            {{ props.row.riskDesc }}
          </b-table-column>
        </ContextTableCard>
      </div>
    </div>
  </section>
</template>

<script>
import SelectLocation from "@/components/SelectLocation.vue";
import RoleSearchNClear from "@/components/RoleSearchNClear.vue";
import ContextTableCard from "@/components/ContextTableCard.vue";
import ContextTableMixin from "@/mixins/contextTableMixin";
import ActionDialog from "@/components/dialogs/ActionDialog.vue";

import ActionStatus from "@/enums/actionStatus.js";
import ActionType from "@/enums/actionType.js";
import Dates from "@/common/dates";
import { mapMutations } from "vuex";

export default {
  components: { SelectLocation, RoleSearchNClear, ContextTableCard },
  mixins: [ContextTableMixin],
  data() {
    return {
      url: "riskactions",
      entity: "Actions",
      search: {
        locationId: this.$store.state.risks.search.locationId,
        roleId: null,
        title: this.$store.state.risks.search.title,
        page: 1,
        sortField: "dueDate",
        sortOrder: "asc",
        openOnly: true
      },
      contextOptions: [
        { text: "Edit", event: "edit-action" },
        { text: "View Risk", event: "view-risk" }
      ]
    };
  },
  watch: {
    "search.locationId": "debounceRefreshData",
    "search.roleId": "debounceRefreshData",
    "search.name": {
      handler() {
        this.debounceRefreshData();
        this.setSearch(this.search);
      }
    }
  },
  methods: {
    ...mapMutations("risks", ["setSearch"]),
    shortFormat(date) {
      return Dates.shortFormat(date);
    },
    setActionColour(row) {
      return ActionStatus.Colour[row.status] + "-selected";
    },
    icon(status) {
      return ActionStatus.Icon[status];
    },
    editAction() {
      this.$buefy.modal.open({
        parent: this,
        component: ActionDialog,
        hasModalCard: true,
        props: { risk: this.selected, action: this.selected, actionType: ActionType.Enum.Risk },
        events: { refresh: this.debounceRefreshData }
      });
    },
    contextMenuClicked(event) {
      switch (event) {
        case "view-risk":
          this.$router.push({ name: "risk.edit", params: { id: this.selected.riskId } });
          break;
        case "edit-action":
          this.editAction();
          break;
      }
    },
    clearSearch() {
      this.search.locationId = null;
      this.search.roleId = null;
    }
  }
};
</script>
